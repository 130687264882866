
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as hubspotY89JVRYl926llRwlAjtoTVc9NkIKpN_45W9TkzTrHXMjkMeta } from "/app/pages/hubspot.vue?macro=true";
import { default as _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexXDrvC4X9cQh98rUTaKV7n9SAzKfJcTLnbJGv9g_YdesMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as newsletterWvr_45ldoWfuImUOgsCqZIsZVFmncZcSS9UXn1BfhV83IMeta } from "/app/pages/newsletter.vue?macro=true";
import { default as trusted_45ai_45h6ZvyaZDwfSDMjxUqLyXIy79_45sSO6bNYvsBygtDQqMMeta } from "/app/pages/trusted-ai.vue?macro=true";
import { default as index1O1TF2sk8F9YB5cJCK_45Hmm58rM16X4mEdE1sG36MhqUMeta } from "/app/pages/vlogs/index.vue?macro=true";
import { default as _91id_93L9DDZj_45oyyPwMpWz0tqK1NdlQQlzkXmLjfONftjjCm0Meta } from "/app/pages/careers/[id].vue?macro=true";
import { default as indexrDqMSVkmqJ_45K4ru_jaNFtZOaP2MAuxd4p4vlycfVaVcMeta } from "/app/pages/events/index.vue?macro=true";
import { default as _91_46_46_46slug_932PC_PiUkiv7B66AMBCFTaMG_uP34FruhdFGydpeUoPEMeta } from "/app/pages/lp/[...slug].vue?macro=true";
import { default as indexwSXudBzCI6hxsl49xqf7VXZq_7itvx_45Ae_45Ebad_45BrDQMeta } from "/app/pages/careers/index.vue?macro=true";
import { default as _91slug_93fnkmtcHopPEaKqCcF8vqCph8nUsVQRTcFs9qi3539cwMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexP_T7wPh_aodPu5ikFWVi4tb5ggCS9bYgYggORuX_45lvAMeta } from "/app/pages/reports/index.vue?macro=true";
import { default as indexBYf_MWCFB_45gipQuVebsgX_xioG4aF_bZ5HnTM_452U7KAMeta } from "/app/pages/features/index.vue?macro=true";
import { default as indexfUIpqSs7ui2O2Q_45hjrkHNLLxiVUi7OdJwCsWMBV7ZQEMeta } from "/app/pages/newsroom/index.vue?macro=true";
import { default as indexo3spBEKVcOAAnRYw14uDziYhGPGPfahlX3bWXhxhhesMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as _91slug_93X_BBZMYUC5oCHObZ0Gzw9fO1Z1aWHKZwCoTcuCWzPAcMeta } from "/app/pages/reports/[slug].vue?macro=true";
import { default as indexAAZ_451jbRCcsYOhAKobPue0y5s6uvkOdAFy9R6TDVVRQMeta } from "/app/pages/webinars/index.vue?macro=true";
import { default as _91slug_93A5_xElfRtCNJts1J9i0t_JUqd6PM1y_9vWrmJzfu9doMeta } from "/app/pages/partners/[slug].vue?macro=true";
import { default as _91slug_93_q6U2xXrl1p_45ti7pPYJZnt7W_459DcGjCxnYgj5lFtSyoMeta } from "/app/pages/webinars/[slug].vue?macro=true";
import { default as indexj91aqoCCySy693svAnAPMW0SkuUq3qD6baJRdRQPU2QMeta } from "/app/pages/blog/authors/index.vue?macro=true";
import { default as indexJyQckyHQgyRzo9rXHk1OF9m7xP_qx5xdvCrduKhRZfMMeta } from "/app/pages/blog/articles/index.vue?macro=true";
import { default as _91slug_93e7e48Yz5lqBUCLpmhltM_7ghntWwu0faEh7I9tSpZ7UMeta } from "/app/pages/blog/authors/[slug].vue?macro=true";
import { default as our_45awardsUZgWSF1_Y1rLmP7vm2Ieq8q_ZCtkgaNE7_45pDhLb4urYMeta } from "/app/pages/newsroom/our-awards.vue?macro=true";
import { default as _91slug_93LPVqP082F_45d2Pp4ILMnoYVg8r8tQe_fjPGg857OHwtcMeta } from "/app/pages/blog/articles/[slug].vue?macro=true";
import { default as in_45the_45newsGhtnWHk65hmOJPJeQKIxD66HtSZmf0SfONxUhlZJQEgMeta } from "/app/pages/newsroom/in-the-news.vue?macro=true";
import { default as indexv7FASn7WXpJQGaotTU_th1nCiVx6WIqcs90wxf4KwpQMeta } from "/app/pages/blog/categories/index.vue?macro=true";
import { default as _91slug_93rz_45ofCaccPJS_45qEsy2De3K5YDzMBUV3wggCESSy_45uIsMeta } from "/app/pages/blog/categories/[slug].vue?macro=true";
import { default as press_45releasesVeUvUb3kZ9DY0ijYpeiNC6D8abmE4msBgwEq2S5YNQ0Meta } from "/app/pages/newsroom/press-releases.vue?macro=true";
import { default as implementation_45satisfaction_45surveymFiGhhxEuqWbt57F2zSDlpT62yaxyiunfHDr23thHVoMeta } from "/app/pages/implementation-satisfaction-survey.vue?macro=true";
import { default as indexwDwo2UHCBaedocS_45OjqM6u_45AwThPm0oBCc16QO85gWgMeta } from "../pages/blog/articles/index.vue?macro=true";
import { default as component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "hubspot___en",
    path: "/hubspot",
    component: () => import("/app/pages/hubspot.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "newsletter___en",
    path: "/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "newsletter___de",
    path: "/de/newsletter",
    component: () => import("/app/pages/newsletter.vue")
  },
  {
    name: "trusted-ai___en",
    path: "/trusted-ai",
    meta: trusted_45ai_45h6ZvyaZDwfSDMjxUqLyXIy79_45sSO6bNYvsBygtDQqMMeta || {},
    component: () => import("/app/pages/trusted-ai.vue")
  },
  {
    name: "trusted-ai___de",
    path: "/de/trusted-ai",
    meta: trusted_45ai_45h6ZvyaZDwfSDMjxUqLyXIy79_45sSO6bNYvsBygtDQqMMeta || {},
    component: () => import("/app/pages/trusted-ai.vue")
  },
  {
    name: "vlogs___en",
    path: "/vlogs",
    component: () => import("/app/pages/vlogs/index.vue")
  },
  {
    name: "vlogs___de",
    path: "/de/vlogs",
    component: () => import("/app/pages/vlogs/index.vue")
  },
  {
    name: "careers-id___en",
    path: "/careers/:id()",
    component: () => import("/app/pages/careers/[id].vue")
  },
  {
    name: "careers-id___de",
    path: "/de/careers/:id()",
    component: () => import("/app/pages/careers/[id].vue")
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "lp-slug___en",
    path: "/lp/:slug(.*)*",
    meta: _91_46_46_46slug_932PC_PiUkiv7B66AMBCFTaMG_uP34FruhdFGydpeUoPEMeta || {},
    component: () => import("/app/pages/lp/[...slug].vue")
  },
  {
    name: "lp-slug___de",
    path: "/de/lp/:slug(.*)*",
    meta: _91_46_46_46slug_932PC_PiUkiv7B66AMBCFTaMG_uP34FruhdFGydpeUoPEMeta || {},
    component: () => import("/app/pages/lp/[...slug].vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___de",
    path: "/de/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "reports___en",
    path: "/reports",
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "reports___de",
    path: "/de/reports",
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "features___en",
    path: "/features",
    component: () => import("/app/pages/features/index.vue")
  },
  {
    name: "newsroom___en",
    path: "/newsroom",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "partners___en",
    path: "/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "reports-slug___en",
    path: "/reports/:slug()",
    meta: _91slug_93X_BBZMYUC5oCHObZ0Gzw9fO1Z1aWHKZwCoTcuCWzPAcMeta || {},
    component: () => import("/app/pages/reports/[slug].vue")
  },
  {
    name: "reports-slug___de",
    path: "/de/reports/:slug()",
    meta: _91slug_93X_BBZMYUC5oCHObZ0Gzw9fO1Z1aWHKZwCoTcuCWzPAcMeta || {},
    component: () => import("/app/pages/reports/[slug].vue")
  },
  {
    name: "webinars___en",
    path: "/webinars",
    component: () => import("/app/pages/webinars/index.vue")
  },
  {
    name: "webinars___de",
    path: "/de/webinars",
    component: () => import("/app/pages/webinars/index.vue")
  },
  {
    name: "partners-slug___en",
    path: "/partners/:slug()",
    meta: _91slug_93A5_xElfRtCNJts1J9i0t_JUqd6PM1y_9vWrmJzfu9doMeta || {},
    component: () => import("/app/pages/partners/[slug].vue")
  },
  {
    name: "webinars-slug___en",
    path: "/webinars/:slug()",
    meta: _91slug_93_q6U2xXrl1p_45ti7pPYJZnt7W_459DcGjCxnYgj5lFtSyoMeta || {},
    component: () => import("/app/pages/webinars/[slug].vue")
  },
  {
    name: "webinars-slug___de",
    path: "/de/webinars/:slug()",
    meta: _91slug_93_q6U2xXrl1p_45ti7pPYJZnt7W_459DcGjCxnYgj5lFtSyoMeta || {},
    component: () => import("/app/pages/webinars/[slug].vue")
  },
  {
    name: "blog-authors___en",
    path: "/blog/authors",
    component: () => import("/app/pages/blog/authors/index.vue")
  },
  {
    name: "blog-articles___en",
    path: "/blog/articles",
    component: () => import("/app/pages/blog/articles/index.vue")
  },
  {
    name: "blog-authors-slug___en",
    path: "/blog/authors/:slug()",
    component: () => import("/app/pages/blog/authors/[slug].vue")
  },
  {
    name: "newsroom-our-awards___en",
    path: "/newsroom/our-awards",
    component: () => import("/app/pages/newsroom/our-awards.vue")
  },
  {
    name: "blog-articles-slug___en",
    path: "/blog/articles/:slug()",
    component: () => import("/app/pages/blog/articles/[slug].vue")
  },
  {
    name: "newsroom-in-the-news___en",
    path: "/newsroom/in-the-news",
    component: () => import("/app/pages/newsroom/in-the-news.vue")
  },
  {
    name: "blog-categories___en",
    path: "/blog/categories",
    component: () => import("/app/pages/blog/categories/index.vue")
  },
  {
    name: "blog-categories-slug___en",
    path: "/blog/categories/:slug()",
    component: () => import("/app/pages/blog/categories/[slug].vue")
  },
  {
    name: "newsroom-press-releases___en",
    path: "/newsroom/press-releases",
    component: () => import("/app/pages/newsroom/press-releases.vue")
  },
  {
    name: "implementation-satisfaction-survey___en",
    path: "/implementation-satisfaction-survey",
    meta: implementation_45satisfaction_45surveymFiGhhxEuqWbt57F2zSDlpT62yaxyiunfHDr23thHVoMeta || {},
    component: () => import("/app/pages/implementation-satisfaction-survey.vue")
  },
  {
    path: "/blog/articles/page-0",
    name: "articles zero page",
    redirect: "/blog/articles"
  },
  {
    path: "/blog/articles/page-1",
    name: "articles first page",
    redirect: "/blog/articles"
  },
  {
    name: "articles pagination___en",
    path: "/blog/articles/page-:pageNumber(\\d+)",
    component: () => import("../pages/blog/articles/index.vue")
  },
  {
    name: "articles pagination___de",
    path: "/de/blog/articles/page-:pageNumber(\\d+)",
    component: () => import("../pages/blog/articles/index.vue")
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/diagnostic-engine",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/diagnostic-engine",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/what-can-we-learn-about-people-using-symptom-checkers",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/diagnostic-engine",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/diagnostic-engine",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/product/infermedica-api",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/product/infermedica-api",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/product/infermedica-api",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/product/infermedica-api",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/pl/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/pl/product/intake-form",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/external-validation",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/external-validation",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/external-validation",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/external-validation",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/industries/health-plans",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/industries/health-plans",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/case-studies/solv-full-read",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/case-studies/solv-full-read",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/case-studies/solv-full-read",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/case-studies/solv-full-read",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/triage",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/triage",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/triage",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/triage",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/intake",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/intake",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/intake",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/intake",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/press",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  },
  {
    name: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51AMeta?.name,
    path: "/de/de/press",
    component: component_45stubpfwMWN16Uv7wyqP7SZ1BDABger5wolVeykNo15tb51A
  }
]