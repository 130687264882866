import payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_qYPg_6N88z11x4rBlzSgR9OmgDmZwikpnALZnfOV4ik from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sukVNFifxrJ4HOUrlN3c9wcP7DuT0fiKUtOTgjMAyfo from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VIRZYp_ikFfO_t_D6wLltHHzSzgVbPzUBiT_geaNtLo from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_UuwJ_CK0hlP3tpyjRJlTy_bfiAhRAtZR1Vk1dGEdRDA from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_o99uSBeJgMD3gGl0C5cDTd4iV3GKWtlohYkVGnCqGvs from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kZrNm_NWUyyIYqvtU03w9_H_YtIBTdlkcxCSzS8LzGU from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_35Q1_HWRCgWk7ELKgz_X6fcJ8myb5CITDK3CtAB6kXg from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_4563390065e97b7d4740aac078857814/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_AX0MfSRegDpgJazEvbQPnznC7f0t1_WZiVH7nSTXg9U from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_4563390065e97b7d4740aac078857814/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_pSOVTzb6aqZ1u9KSZe46ezTJB7FfNnOcf4Lfx9_ZHuc from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_4563390065e97b7d4740aac078857814/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_G5b5X7LguJlO0FrkcdPb0fPaVm2fZYy9AdHzEqMQk1Q from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0 from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_AQ0Fljk7xe90rKi5dbAR2MDpzsSbXxb3hWXM4S1Z31w from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import motion_xA2QjStZMgs_JHWJafkTkSddZY7lbRYLApNAr6bB6Jg from "/app/node_modules/.pnpm/@vueuse+motion@3.0.3_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import clickOutsideDirective_yVNa6wWloedJTnA0YzXvxMiLt1Gn80FB387x8uo_xpQ from "/app/plugins/clickOutsideDirective.ts";
import fetchGlobalData_jkvoIXCahZUp5A5fxTxev0StyFU49UOTa30fJ_J___I from "/app/plugins/fetchGlobalData.ts";
import onLanguageSwitch_3n39FJrI5JrMr4RASwHhIInxUJ_SBR8nTL_tW4M9M1Q from "/app/plugins/onLanguageSwitch.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import ssg_detect_KtIxa9jwfiUBO62CKTbSRm_p4oCAkcpJmyU5UqppteA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_4563390065e97b7d4740aac078857814/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw,
  revive_payload_client_qYPg_6N88z11x4rBlzSgR9OmgDmZwikpnALZnfOV4ik,
  unhead_sukVNFifxrJ4HOUrlN3c9wcP7DuT0fiKUtOTgjMAyfo,
  router_VIRZYp_ikFfO_t_D6wLltHHzSzgVbPzUBiT_geaNtLo,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  navigation_repaint_client_UuwJ_CK0hlP3tpyjRJlTy_bfiAhRAtZR1Vk1dGEdRDA,
  check_outdated_build_client_o99uSBeJgMD3gGl0C5cDTd4iV3GKWtlohYkVGnCqGvs,
  chunk_reload_client_kZrNm_NWUyyIYqvtU03w9_H_YtIBTdlkcxCSzS8LzGU,
  switch_locale_path_ssr_35Q1_HWRCgWk7ELKgz_X6fcJ8myb5CITDK3CtAB6kXg,
  route_locale_detect_AX0MfSRegDpgJazEvbQPnznC7f0t1_WZiVH7nSTXg9U,
  i18n_pSOVTzb6aqZ1u9KSZe46ezTJB7FfNnOcf4Lfx9_ZHuc,
  plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_G5b5X7LguJlO0FrkcdPb0fPaVm2fZYy9AdHzEqMQk1Q,
  nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0,
  i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI,
  plugin_AQ0Fljk7xe90rKi5dbAR2MDpzsSbXxb3hWXM4S1Z31w,
  motion_xA2QjStZMgs_JHWJafkTkSddZY7lbRYLApNAr6bB6Jg,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  clickOutsideDirective_yVNa6wWloedJTnA0YzXvxMiLt1Gn80FB387x8uo_xpQ,
  fetchGlobalData_jkvoIXCahZUp5A5fxTxev0StyFU49UOTa30fJ_J___I,
  onLanguageSwitch_3n39FJrI5JrMr4RASwHhIInxUJ_SBR8nTL_tW4M9M1Q,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  ssg_detect_KtIxa9jwfiUBO62CKTbSRm_p4oCAkcpJmyU5UqppteA
]