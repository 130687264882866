import validate from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45redirects_45global from "/app/middleware/1.redirects.global.ts";
import _2_45errors_45global from "/app/middleware/2.errors.global.ts";
import _3_45redirect_45trailing_45slash_45global from "/app/middleware/3.redirectTrailingSlash.global.ts";
import _4_45sql_45injection_45protection_45global from "/app/middleware/4.sqlInjectionProtection.global.ts";
import route_45global from "/app/middleware/route.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.24.0_jiti@2.4_481fad61c95fc5873a32dcc6ba79e37f/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45redirects_45global,
  _2_45errors_45global,
  _3_45redirect_45trailing_45slash_45global,
  _4_45sql_45injection_45protection_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-lp-pages": () => import("/app/middleware/redirectLpPages.ts")
}