
import * as storyblokRuntime$gb7uncDmWCmzYhzgRd8isHOc66DDh91JO5BRwBRKuDo from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$_9TjbMFv4aGrmPmLtvxHhtS9Bjxv1zDTqgo2ac6UnImE from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 768,
    "md": 1024,
    "lg": 1200,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "infermedica.com",
    "a.storyblok.com"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$gb7uncDmWCmzYhzgRd8isHOc66DDh91JO5BRwBRKuDo, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$_9TjbMFv4aGrmPmLtvxHhtS9Bjxv1zDTqgo2ac6UnImE, defaults: {} }
}
        