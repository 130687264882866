import { defineNuxtRouteMiddleware, navigateTo, createError } from '#app'

/**
 * Simple SQL injection patterns for client-side detection
 * Less comprehensive than server-side, but provides an extra layer of protection
 */
const BASIC_SQL_INJECTION_PATTERNS = [
  /'\s*OR\s*'1'\s*=\s*'1/i,
  /'\s*OR\s*1\s*=\s*1/i,
  /SELECT|INSERT|UPDATE|DELETE|DROP|UNION/i,
  /SLEEP\(\d+\)/i,
  /pg_sleep\(\d+\)/i,
  /WAITFOR\s+DELAY/i,
  /;\s*--/i,
]

/**
 * Check if a string contains SQL injection patterns
 */
const containsSqlInjection = (value: string): boolean => {
  if (typeof value !== 'string') return false
  return BASIC_SQL_INJECTION_PATTERNS.some((pattern) => pattern.test(value))
}

/**
 * Check query parameters for SQL injection attempts
 */
const checkQuery = (query: Record<string, string>): boolean => {
  return Object.values(query).some((value) => {
    if (typeof value === 'string') {
      return containsSqlInjection(value)
    }
    return false
  })
}

export default defineNuxtRouteMiddleware((to) => {
  // Client-side protection
  if (process.client && checkQuery(to.query as Record<string, string>)) {
    console.error('Suspicious query parameters detected')

    // Navigate to error page
    return navigateTo('/error', { redirectCode: 403 })
  }
})
